import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { Transition } from 'react-transition-group';

import { Cross } from '@common/components/Icons';

import {
  CloseButtonTopbar,
  GlobalStyle,
  StickyHeader,
  StickyHr,
  StickyWrapper,
  TitleContent,
  TopTitle,
  TopWrapper,
  Wrapper,
  WrapperInner,
} from './Overlay.styled';

export function Overlay({ isOpen, children, onClose, topbarContent }) {
  const [shouldRender, setShouldRender] = useState(isOpen);
  const [isEntered, setIsEntered] = useState(false);

  useEffect(() => {
    function handleKeyPress({ key }) {
      if (isOpen && key === 'Escape') {
        onClose();
      }
    }
    document.addEventListener('keyup', handleKeyPress);

    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [isOpen, onClose]);

  useEffect(() => {
    if (isOpen) {
      setShouldRender(true);
    } else {
      setIsEntered(false);
    }
  }, [isOpen]);

  useEffect(() => {
    if (shouldRender && !isEntered) {
      setIsEntered(true);
    }
  }, [shouldRender]);

  if (!shouldRender) return null;

  const rootElement =
    document.getElementById('__next') ||
    document.getElementById('root') ||
    document.getElementsByTagName('body')[0];

  return ReactDOM.createPortal(
    <React.Fragment>
      <GlobalStyle isOpen={isOpen} />
      <Transition
        in={isEntered}
        onExited={() => setShouldRender(false)}
        timeout={{ exit: 700 }}
      >
        {transitionState => (
          <Wrapper
            aria-modal="true"
            role="dialog"
            transitionState={transitionState}
          >
            {topbarContent && (
              <StickyHeader>
                <StickyWrapper>
                  <TopWrapper>
                    <TopTitle>
                      <TitleContent>{topbarContent}</TitleContent>
                      {onClose && (
                        <CloseButtonTopbar onClick={onClose}>
                          <Cross />
                        </CloseButtonTopbar>
                      )}
                    </TopTitle>
                    <StickyHr />
                  </TopWrapper>
                </StickyWrapper>
              </StickyHeader>
            )}
            <WrapperInner>{children}</WrapperInner>
          </Wrapper>
        )}
      </Transition>
    </React.Fragment>,
    rootElement,
  );
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func,
  topbarContent: PropTypes.node,
};

Overlay.defaultProps = {
  onClose: undefined,
  topbarContent: undefined,
};

export default Overlay;
