import PropTypes from 'prop-types';
import React from 'react';

import {
  ButtonsWrapper,
  ConfirmButton,
  Content,
  ContentInner,
  Description,
  DismissButton,
  Divider,
  DividerVertical,
  Title,
  Wrapper,
  WrapperInner,
} from './ConfirmationDialog.styled';

export function ConfirmationDialog({
  title,
  description,
  dismissText,
  confirmText,
  onDismiss,
  onConfirm,
}) {
  return (
    <Wrapper>
      <WrapperInner>
        <Content>
          <ContentInner>
            <Title>{title}</Title>
            <Description variant="body1">{description}</Description>
          </ContentInner>
          <Divider />
          <ButtonsWrapper>
            <DismissButton
              type="button"
              onClick={onDismiss}
              variant="transparentSecondaryBig"
            >
              {dismissText}
            </DismissButton>
            <DividerVertical />
            <ConfirmButton
              type="button"
              onClick={onConfirm}
              data-testid="confirm-button"
              autoFocus
            >
              {confirmText}
            </ConfirmButton>
          </ButtonsWrapper>
        </Content>
      </WrapperInner>
    </Wrapper>
  );
}

ConfirmationDialog.propTypes = {
  confirmText: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  dismissText: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onDismiss: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

export default ConfirmationDialog;
