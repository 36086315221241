import styled, { createGlobalStyle } from 'styled-components';
import { TransitionStatus } from 'react-transition-group';

import { getGridBase, getThemeTransition, getThemeColor } from '@utils/styled';

import CloseButton from '@common/components/CloseButton';
import Hr from '@common/components/Hr';
import Typography from '@common/components/Typography';

export const GlobalStyle = createGlobalStyle<{ isOpen: boolean }>`
  body {
    overflow: ${props => (props.isOpen ? 'hidden' : undefined)};
  }
`;

export const Wrapper = styled.div<{ transitionState: TransitionStatus }>`
  position: fixed;
  top: ${({ transitionState }) =>
    transitionState === 'entered' ? '0%' : '100%'};
  left: 0;
  z-index: 100;
  overflow: auto;
  min-width: 100vw;
  max-width: 100vw;
  height: 100%;
  min-height: 100%;
  max-height: 100%;
  background-color: ${getThemeColor('grey.100')};
  transition: ${getThemeTransition('top', 'verySlow')};
`;

export const OverlayTitle = styled(Typography).attrs({
  variant: 'paragraph1',
  maxLines: 1,
})`
  display: inline-block;
  margin: 0;
  margin-right: 1.5rem;
  font-weight: 500;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0;
  z-index: 30;
  grid-column: span 12;
  background-color: ${getThemeColor('grey.100')};
`;

export const WrapperInner = styled.div`
  ${getGridBase(12)};
  position: relative;
  width: 100vw;
  max-width: ${({ theme }) => theme.breakpoints[0]}rem;
  height: 100%;
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 0)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 0)};
  margin: 0 auto;
`;

export const StickyWrapper = styled.div`
  ${({ theme }) => theme.getFluidSpacing('padding-left', 'widthBased', 0)};
  ${({ theme }) => theme.getFluidSpacing('padding-right', 'widthBased', 0)};
  max-width: ${({ theme }) => theme.breakpoints[0]}rem;
  margin: 0 auto;
`;

export const StyledCloseButton = styled(CloseButton)`
  margin-left: auto;
  border-color: transparent;
  background-color: transparent;
  ${({ theme }) => theme.getFluidSpacing('margin-right', 'equal', 5, true)};
`;

export const CloseButtonTopbar = styled(StyledCloseButton)`
  flex: 0 0 auto;
  align-self: flex-start;
  margin-top: 0.6rem;
`;

export const TopWrapper = styled.div`
  position: relative;
`;

export const TopTitle = styled.div`
  display: flex;
`;

export const TitleContent = styled.div`
  flex: 1 1 auto;
`;

export const StickyHr = styled(Hr)`
  margin: 0;
`;
