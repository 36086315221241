import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';
import FocusTrap from 'focus-trap-react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import {
  ContentWrapper,
  GlobalStyle,
  StyledCloseButton,
  Wrapper,
  WrapperInner,
} from './Modal.styled';

const OptionalFocusTrap = ({
  alwaysMounted,
  shouldRenderFocusTrap,
  children,
}) => {
  if (alwaysMounted && !shouldRenderFocusTrap) {
    return children;
  }

  return <FocusTrap>{children}</FocusTrap>;
};

OptionalFocusTrap.propTypes = {
  children: PropTypes.node.isRequired,
  alwaysMounted: PropTypes.bool.isRequired,
  shouldRenderFocusTrap: PropTypes.bool.isRequired,
};

export function Modal({
  isOpen,
  children,
  closeHasShadow,
  fullScreen,
  onClose,
  withCloseButton,
  className,
  appear,
  enter,
  exit,
  timeout,
  mountOnEnter,
  isSmallCloseButton,
  isWhiteCloseButton,
  unmountOnExit,
  onExited,
  alwaysMounted,
  shouldRenderFocusTrap,
  onEnter,
}) {
  const [scrollBlocked, setScrollBlocked] = useState(isOpen);
  const wrapperInnerRef = useRef(null);

  useEffect(() => {
    function handleKeyPress({ key }) {
      if (isOpen && key === 'Escape' && onClose) {
        onClose();
      }
    }

    document.addEventListener('keyup', handleKeyPress);

    return () => document.removeEventListener('keyup', handleKeyPress);
  }, [isOpen, onClose]);

  const rootElement = global?.document?.getElementById('__next');

  const handleEntered = () => {
    setScrollBlocked(true);

    if (onEnter) {
      onEnter();
    }
  };

  const handleExited = (...attrs) => {
    setScrollBlocked(false);

    if (onExited) {
      onExited(...attrs);
    }
  };

  const content = (
    <React.Fragment>
      <GlobalStyle isOpen={scrollBlocked} />
      <CSSTransition
        appear={appear}
        enter={enter}
        exit={exit}
        timeout={timeout}
        mountOnEnter={mountOnEnter}
        unmountOnExit={unmountOnExit}
        in={isOpen}
        onEntered={handleEntered}
        onExited={handleExited}
        classNames="modal-transition"
      >
        <OptionalFocusTrap
          alwaysMounted={alwaysMounted}
          shouldRenderFocusTrap={shouldRenderFocusTrap}
        >
          <Wrapper
            aria-modal="true"
            role="dialog"
            className={className}
            aria-hidden={!isOpen}
            timeout={timeout}
          >
            <WrapperInner
              ref={wrapperInnerRef}
              onClick={({ target }) =>
                target === wrapperInnerRef.current && onClose()
              }
            >
              <ContentWrapper fullScreen={fullScreen}>
                {children}
              </ContentWrapper>
            </WrapperInner>
            {onClose && withCloseButton && (
              <StyledCloseButton
                hasShadow={closeHasShadow}
                isSmall={isSmallCloseButton}
                isWhite={isWhiteCloseButton}
                onClick={onClose}
                type="button"
              />
            )}
          </Wrapper>
        </OptionalFocusTrap>
      </CSSTransition>
    </React.Fragment>
  );

  return rootElement ? ReactDOM.createPortal(content, rootElement) : content;
}

Modal.propTypes = {
  alwaysMounted: PropTypes.bool,
  appear: PropTypes.bool,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  enter: PropTypes.bool,
  exit: PropTypes.bool,
  fullScreen: PropTypes.bool,
  isOpen: PropTypes.bool.isRequired,
  isSmallCloseButton: PropTypes.bool,
  isWhiteCloseButton: PropTypes.bool,
  mountOnEnter: PropTypes.bool,
  onClose: PropTypes.func,
  onExited: PropTypes.func,
  timeout: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.shape({
      appear: PropTypes.number,
      enter: PropTypes.number,
      exit: PropTypes.number,
    }),
  ]).isRequired,
  unmountOnExit: PropTypes.bool,
  withCloseButton: PropTypes.bool,
  shouldRenderFocusTrap: PropTypes.bool,
  closeHasShadow: PropTypes.bool,
  onEnter: PropTypes.func,
};

Modal.defaultProps = {
  alwaysMounted: false,
  appear: undefined,
  className: undefined,
  enter: undefined,
  exit: undefined,
  fullScreen: undefined,
  isSmallCloseButton: false,
  isWhiteCloseButton: false,
  mountOnEnter: undefined,
  onClose: undefined,
  onExited: undefined,
  unmountOnExit: undefined,
  withCloseButton: true,
  shouldRenderFocusTrap: false,
  closeHasShadow: undefined,
  onEnter: undefined,
};

export default Modal;
