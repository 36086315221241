import styled, { css } from 'styled-components';

import { getThemeTransition, getThemeColor } from '@utils/styled';

import Button, { BUTTON_VARIANTS } from '@common/components/Button';
import Hr from '@common/components/Hr';
import Typography from '@common/components/Typography';

export const Wrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: fit-content;
  height: min-content;
  margin: auto;
  transition: ${getThemeTransition('opacity')};

  .modal-transition-enter & {
    opacity: 0;
  }

  .modal-transition-exit &,
  .modal-transition-enter-active &,
  .modal-transition-enter-done & {
    opacity: 1;
  }

  .modal-transition-exit-done &,
  .modal-transition-exit-active & {
    opacity: 0;
  }
`;

export const WrapperInner = styled.div`
  width: fit-content;
  max-width: ${({ theme }) => theme.breakpoints[0]}rem;
  margin: auto;
`;

export const Content = styled.div`
  max-width: ${({ theme }) => theme.smallestBreakpoint}rem;
  margin: auto;
  border-radius: ${({ theme }) => theme.borderRadiuses['normal.2']}rem;
  background-color: ${getThemeColor('grey.100')};
`;

export const ContentInner = styled.div`
  ${({ theme }) => theme.getFluidSpacing('padding', 'scale', 5)};
`;

export const Divider = styled(Hr)`
  margin: 0;
`;

export const ButtonsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
`;

export const DividerVertical = styled.div`
  width: 1px;
  height: 100%;
  background-color: ${getThemeColor('bluegrey.90')};
`;

export const Title = styled(Typography).attrs({ variant: 'paragraph1' })`
  font-weight: bold;
`;

export const Description = styled(Typography).attrs({ variant: 'body1' })`
  margin: 0;
`;

const ButtonStyles = css`
  padding: 0;
  font-size: 1.6rem;

  &:hover,
  &:focus,
  &:active {
    background-color: ${getThemeColor('bluegrey.97')};
  }
`;

export const DismissButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LEVEL_4_BLUEGREY_15,
})`
  ${ButtonStyles}
`;

export const ConfirmButton = styled(Button).attrs({
  variant: BUTTON_VARIANTS.LEVEL_4_RED,
})`
  ${ButtonStyles}
  font-weight: bold;
`;
