import React from 'react';
import PropTypes from 'prop-types';

import { StyledHr } from './Hr.styled';

export const Hr = props => <StyledHr {...props} />;

Hr.propTypes = {
  color: PropTypes.string,
  spacings: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
};

Hr.defaultProps = {
  color: 'bluegrey.90',
  spacings: ['scale', 3],
};

export default Hr;
