import styled, { createGlobalStyle } from 'styled-components';

import { getThemeTransition } from '@utils/styled';

import CloseButton from '@common/components/CloseButton';
import { PageWrapper } from '@common/containers/Layout/Layout.styled';

export const GlobalStyle = createGlobalStyle`
  body {
    overflow: ${({ isOpen }) => (isOpen ? 'hidden' : undefined)};
  }
`;

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 101;
  overflow: auto;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
  background: rgba(0, 0, 0, 0.8);
  transition: ${getThemeTransition('background-color')};

  &.modal-transition-enter {
    background: rgba(0, 0, 0, 0);
  }

  &.modal-transition-enter-active {
    background: rgba(0, 0, 0, 0.8);
  }

  &.modal-transition-enter-done,
  &.modal-transition-exit {
    background: rgba(0, 0, 0, 0.8);
  }

  &.modal-transition-exit-done,
  &.modal-transition-exit-active {
    background: rgba(0, 0, 0, 0);
    transition-delay: ${({ timeout }) =>
      Math.max(0, (timeout.exit ?? timeout) - 300)}ms;
  }
`;

export const WrapperInner = styled.div`
  display: flex;
  min-width: 100vw;
  max-width: 100vw;
  min-height: 100vh;
  max-height: 100vh;
`;

export const ContentWrapper = styled(PageWrapper)`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  max-width: ${({ fullScreen, theme }) =>
    fullScreen ? '100vw' : `${theme.breakpoints[0]}rem`};
`;

export const StyledCloseButton = styled(CloseButton)`
  position: fixed;
  top: 3rem;
  right: 3rem;
  z-index: 30;
  transition: ${getThemeTransition('all')};

  .modal-transition-enter & {
    opacity: 0;
  }

  .modal-transition-enter-active & {
    opacity: 1;
  }

  .modal-transition-enter-done & {
    opacity: 1;
  }

  .modal-transition-exit & {
    opacity: 1;
  }

  .modal-transition-exit-done &,
  .modal-transition-exit-active & {
    opacity: 0;
  }
`;
